import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Button, { buttonColors } from '../../atoms/button';
import Typography from '../../atoms/typography';

const StyledButton = styled(Button)`
  display: flex;

  svg {
    align-self: center;
    fill: transparent;
    stroke: ${(props) => (props.isText ? props.theme.color.brand : buttonColors(props).color)};
    height: 2.7rem;
    width: 2.7rem;
    margin-right: 1rem;
  }
`;

const Text = styled(Typography)``;

const IconButton = ({
  children,
  className,
  clicked,
  disabled,
  handleOnPromise,
  handleOnSuccess,
  inverted,
  isText,
  onClick,
  onError,
  outline,
  promise,
  text,
  isWishlist,
  ...rest
}) => (
  <StyledButton
    promise={promise}
    handleOnPromise={handleOnPromise}
    handleOnSuccess={handleOnSuccess}
    onError={onError}
    isText={isText}
    inverted={inverted}
    outline={outline}
    onClick={clicked || onClick}
    className={className}
    disabled={disabled}
    data-test-id="wishlist-button"
    {...rest}>
    {children}
    <Text element="i" like={isWishlist ? 'dec-4' : 'label-1'} data-test-id="wishlist-text-general-user">
      {text}
    </Text>
  </StyledButton>
);

IconButton.defaultProps = {
  className: '',
  disabled: false,
  inverted: false,
  isText: true,
  outline: false,
  clicked: null,
  onClick: null,
  onError: undefined,
  handleOnPromise: undefined,
  handleOnSuccess: undefined,
  promise: undefined,
  isWishlist: false
};

IconButton.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  clicked: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  inverted: PropTypes.bool,
  isText: PropTypes.bool,
  onError: PropTypes.func,
  handleOnPromise: PropTypes.func,
  handleOnSuccess: PropTypes.func,
  outline: PropTypes.bool,
  promise: PropTypes.func,
  text: PropTypes.string.isRequired,
  isWishlist: PropTypes.bool
};

IconButton.whyDidYouRender = true;

export default IconButton;
